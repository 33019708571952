import client1 from "../Screenshot 2024-08-17 140505.png";
import client2 from "../Screenshot 2024-08-17 141033.png";
import client3 from "../nithya.png";
import client4 from "../Screenshot 2024-08-17 141333.png";
import client5 from "../paakashala.png";
import client6 from "../v4clicks.png";

const clients = [client1, client2, client3, client4, client5];

export default clients;
