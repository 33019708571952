import React from "react";

function Card({ heading, content, icon }) {
  return (
    <div>
      <div className="card">
        <span className="material-symbols-outlined">{icon}</span>
        <h4 className="card_heading">{heading}</h4>
        <p className="card_body">{content}</p>
      </div>
    </div>
  );
}

export default Card;
