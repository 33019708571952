import React from "react";
import "../styles/Loader.css";
import loader from "../elink new1.png";

function Loader() {
  return (
    <div className="loading-screen">
      <div className="loading-spinner">
        <img src={loader} alt="" height={100} width={100} />
      </div>
    </div>
  );
}

export default Loader;
