import React from "react";

export default function ThemeSwitcher() {
  const changeThemeToDark = () => {
    document.documentElement.setAttribute("data-theme", "dark");
    localStorage.setItem("data-theme", "dark");
    console.log("I give you dark");
  };

  const changeThemeToLight = () => {
    document.documentElement.setAttribute("data-theme", "light");
    localStorage.setItem("data-theme", "light");
    console.log("I give you light");
  };

  const switchTheme = () => {
    let theme = localStorage.getItem("data-theme");
    console.log(theme);
    if (theme === "dark") {
      changeThemeToLight();
    } else {
      changeThemeToDark();
    }
  };
  return (
    <div>
      <button id="theme__switcher" type="button" onClick={switchTheme}>
        <span className="material-symbols-outlined">dark_mode</span>
      </button>
    </div>
  );
}
