const CardData = [
  {
    heading: "Web Development",
    content:
      "We design and build responsive, user-friendly websites that not only look stunning but also deliver seamless performance. From concept to launch, we create web experiences that engage, inspire, and drive results.",
    icon: "language",
  },
  {
    heading: "Social Media Marketing",
    content:
      "We craft targeted strategies, engaging content, and impactful campaigns that connect with your audience, drive growth, and turn followers into loyal customers. Let's make your brand the talk of the digital world!",
    icon: "groups",
  },
  {
    heading: "SEO & PPC",
    content:
      "Is your website lost among search engine results? Your local business can be at the top of their search results. E-link, will enhance your online presence. While your competitors get lost in the shuffle, you can drive more organic traffic and orders.",
    icon: "travel_explore",
  },
  {
    heading: "Advertising",
    content:
      "Maximize your brand's reach with our targeted Advertising. We create compelling ad campaigns that captivate your audience and boost visibility. From digital ads to traditional media, we ensure your message resonates and delivers results.",
    icon: "featured_video",
  },
  {
    heading: "Lead Generation",
    content:
      "Accelerate your business growth with our powerful Lead Generation services. We identify and attract high-quality prospects, turning interest into action. Through strategic targeting, we deliver leads, helping you build a robust sales pipeline.",
    icon: "linked_services",
  },
  {
    heading: "Youtube Marketing",
    content:
      "We create engaging video content, optimize your channel, and craft strategies that grow your audience and boost visibility. From compelling storytelling to data-driven campaigns, we help you turn views into loyal subscribers and customers.",
    icon: "youtube_activity",
  },
];

export default CardData;
