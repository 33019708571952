import React, { useEffect, useState } from "react";
import logo from "../elink new.png";
import ThemeSwitcher from "./ThemeSwitcher";
import { Link } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import SideMenu from "./SideMenu";

function Header() {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);
  const headers = [
    {
      redirect: "landing_section",
      name: "Home",
    },
    {
      redirect: "service_section",
      name: "Services",
    },
    {
      redirect: "clients_section",
      name: "Clients",
    },
  ];
  return (
    <div className="header">
      <div className="header_left">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="header_center">
        {headers.map((header) => (
          <Link
            to={header.redirect}
            smooth={true}
            spy={true}
            activeClass="active"
            style={{ textDecoration: "none" }}>
            <p>{header.name}</p>
          </Link>
        ))}
      </div>
      <div className="header_right">
        <Link
          to="contact_us"
          smooth={true}
          spy={true}
          className="contact_button">
          <p>Contact Us</p>
        </Link>
        <ThemeSwitcher />
      </div>
      <div className="mobile_nav">
        {headers.map((header, index) => {
          if (index === 0) {
            return null;
          } else {
            return (
              <Link
                to={header.redirect}
                smooth={true}
                spy={true}
                activeClass="active"
                style={{ textDecoration: "none", cursor: "pointer" }}>
                <p>{header.name}</p>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Header;
